import {GraphqlQueryTable} from "../GraphqlQueryTable.jsx";
import {CustomP} from "./customP/customP.jsx";
import {ToastContainer} from "react-toastify";
import React, {useEffect, useState} from "react";
import {DeleteSubscriptionButton} from "../common/button/subscription/DeleteSubscriptionButton.jsx";
import {TriggerGenerationButton} from "../common/button/subscription/TriggerGenerationButton.jsx";
import {RUN_QUERY} from "./constants.js";
import {IncompleteFilter} from "../common/button/subscription/IncompleteFilter.jsx";
import {EditSubscriptionButton} from "../common/button/subscription/EditSubscriptionButton.jsx";
import {
    fetchLatestPeriod,
    formatPeriod,
    getPeriodAfter,
    getPeriodBefore,
    parseSpinsPeriod
} from "../../../helpers/periods.js";
import {CircularProgress} from "@mui/material";
import {PowertabsHeaderBuilder} from "./PowertabsHeader.jsx";

export const SUBSCRIPTIONS_QUERY = `query ($period: String){
        powertabsGlobalEmailList
        data:powertabsSubscriptions(period: $period) {
            id
            filePreFixName
            clientBrands {
                displayName,
                id
            }
            reportType
            channels {
                displayName
                id
            }
            keyAccounts {
                displayName
                id
            }
            categories {
                displayName
                id
            }
            subcategories {
                displayName
                id
            }
            competitorBrands {
                displayName
                id
            }
            productAttributes {
                displayName
                id
            }
            customSlicers
            poshtaBrandCode
            toEmail
            ccEmail
            aggregateProductUniverses
            upcProductUniverse
            aggregationPeriods
            newTemplate
        }
    }`

export const DELETE_MUTATION = `mutation($id: String!) {
  deletePowertabsSubscription(id: $id) {
    id
  }
}`

export const RAW_ROW_COUNT_CALLABLE = (response) => response.data.dataAccessMetadata.find(metaData => metaData.fileName === "_raw_upc_all_data.csv.gz").rowCount

const buttons = ([
    {
        Header: "Trigger Powertabs",
        Cell: (props) => (
            <div>
                <TriggerGenerationButton
                    data={props.row.original}
                    buttonText={"Run"}
                    createExcel={true}
                    query={RUN_QUERY}
                    getRawRowCountCallable={RAW_ROW_COUNT_CALLABLE}
                />
            </div>
        )
    },
    {
        Header: "Preview CSVs",
        Cell: (props) => (
            <div>
                <TriggerGenerationButton
                    data={props.row.original}
                    buttonText={"Preview"}
                    createExcel={false}
                    query={RUN_QUERY}
                    getRawRowCountCallable={RAW_ROW_COUNT_CALLABLE}
                />
            </div>
        )
    },
    {
        Header: "Edit Subscription",
        Cell: (props) => (
            <div>
                <EditSubscriptionButton editUrl={`/powertabs/subscriptions/${props.row.original.id}?period=${props.row.original.period}`}/>
            </div>
        )
    },
    {
        Header: "Delete Subscription",
        Cell: (props) => (<div>
            <DeleteSubscriptionButton
                deleteMutation={DELETE_MUTATION}
                id={props.row.original.id}
                filePrefixName={props.row.original.filePreFixName}
            />
        </div>)
    },
    {
        Header: "Incomplete",
        accessor: row => hasIncompleteData(row) ? "Yes" : "No",
        Filter: ({column: {filterValue, setFilter},}) => <IncompleteFilter setFilter={setFilter}
                                                                           filterValue={filterValue}/>
    }
])

const hasIncompleteData = (data) => {
    const result = Object.keys(NESTED_KEYS).map(key => {
        return data[key].map(item => {
            return item.displayName === null
        })
    }).flat()
    return result.some(_ => _)
}


const generateAccessor = (key) => ({
    accessor: row => row[key].map(({displayName}) => displayName),
    Cell: ({row}) => row.original[key].map(c => <CustomP data={c}/>)
})

const NESTED_KEYS = {
    "channels": generateAccessor("channels"),
    "keyAccounts": generateAccessor("keyAccounts"),
    "categories": generateAccessor("categories"),
    "subcategories": generateAccessor("subcategories"),
    "competitorBrands": generateAccessor("competitorBrands"),
    "productAttributes": generateAccessor("productAttributes"),
    "clientBrands": generateAccessor("clientBrands"),
}

const generateBooleanAccessor = (key) => ({
    accessor: key.toString(),
    Cell: ({value}) => String(value)
})

const BOOLEAN_KEYS = {
    "newTemplate": generateBooleanAccessor("newTemplate")
}

const getRenderer = (key) => {
    return NESTED_KEYS[key] ?? BOOLEAN_KEYS[key] ?? {accessor: key}
}

export const POWERTABS_COLUMNS = (data) => {
    const keys = Object.keys(data[0])
    keys.splice(0, 1)
    keys.splice(-1, 1)
    return buttons.concat(keys.map(key => ({
        Header: key,
        ...getRenderer(key)
    })));
}

export const fetchPeriodChoices = async () => {
    return getPeriodChoices(await fetchLatestPeriod())
}

export const getPeriodChoices = (currentSpinsPeriod) => {
    const period = parseSpinsPeriod(currentSpinsPeriod);
    const before = getPeriodBefore(period.year, period.period);
    const after = getPeriodAfter(period.year, period.period);
    return [
        formatPeriod(before.year, before.period),
        currentSpinsPeriod,
        formatPeriod(after.year, after.period),
    ]
}


export const Powertabs = () => {
    const [period, setPeriod] = useState(null)
    const [periodChoices, setPeriodChoices] = useState({})
    useEffect(() => {
        fetchPeriodChoices()
            .then((choices) => {
                setPeriodChoices(choices)
                setPeriod(choices[1])
            })
    }, [])

    return !period ? (<CircularProgress className={"spinning-loader"}/>) : (
        <div>
            <ToastContainer autoClose={false}/>
            <GraphqlQueryTable
                pageName="Powertabs Subscriptions"
                generateColumns={POWERTABS_COLUMNS}
                query={SUBSCRIPTIONS_QUERY}
                optionalAboveTheTableComponent={PowertabsHeaderBuilder(period, periodChoices, setPeriod)}
                queryParams={{
                    period: period,
                }}
            />
        </div>
    )
}

