import React, {useEffect, useState} from "react";
import axios from "axios";
import {DataGrid, gridClasses, useGridApiRef} from "@mui/x-data-grid";
import {Button, CircularProgress, Paper, styled} from "@mui/material";
import ConfirmationDialog from "../../common/dialog/ConfirmationDialog.jsx";
import jwtDecode from "jwt-decode";
import {toast} from "react-toastify";
import * as _ from "lodash";
import {CalendarChangeLog} from "./CalendarChangeLog.jsx";

const removeId = (obj) => _.omit(obj, 'id')

export function ReleaseScheduleCalendar() {
    const auth0AccessToken = window.localStorage.getItem('access_token')
    const [isLoading, setIsLoading] = useState(false);
    const [calendar, setCalendar] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [openOverwriteDialog, setOpenOverwriteDialog] = useState(false)
    const [changes, setChanges] = useState(null)

    const userEmail = auth0AccessToken ? jwtDecode(auth0AccessToken)['https://aot/email'] : ""

    const apiRef = useGridApiRef()

    useEffect(() => {
        let isValid = true;
        const abortController = new AbortController()
        setIsLoading(true)
        const fetchCalendar = async () => {
            return await axios.get(`${process.env.REACT_APP_RIS_API_URL}/legacy-scorecards/calendar/release-schedule`, {
                headers: {
                    'authorization': `Bearer ${auth0AccessToken}`
                },
                signal: abortController.signal
            })
        }
        fetchCalendar().then(({data}) => {
            if (isValid) {
                setCalendar(data.rows.map((r, index) => {
                    return {id: index, ...r}
                }))
                setHeaders(data.headers)
            }
        }).finally(() => {
            setIsLoading(false)
        })
        return () => {
            isValid = false
            abortController.abort()
        }
    }, [auth0AccessToken, setCalendar, setHeaders]);

    const handleAddButton = () => {
        apiRef.current.updateRows([{id: apiRef.current.getRowsCount()}])
        if (apiRef.current.getRowsCount() > 12) {
            apiRef.current.updateRows([{id: apiRef.current.getRowsCount() - 13, className: "for-removal"}])
        }
        window.scroll(0, apiRef.current.rootElementRef.current.scrollHeight / 2)
    }
    const handleRemoveButton = () => {
        const selectedRow = apiRef.current.getSelectedRows().entries().next()
        const selectedRowId = selectedRow.value[0]
        apiRef.current.updateRows([{ id: selectedRowId, _action: "delete" }])
    }
    const handleConfirm = (value) => {
        setOpenOverwriteDialog(false)
        if (value) {
            axios.put(`${process.env.REACT_APP_RIS_API_URL}/legacy-scorecards/calendar/release-schedule`, {
                rows: calendar.map(removeId),
                headers: headers
            }, {
                headers: {
                    'authorization': `Bearer ${auth0AccessToken}`
                }
            }).then(({data}) => {
                toast.success(`Successfully updated: ${data.url}`)
                const {generation} = data
                axios.post(`${process.env.REACT_APP_RIS_API_URL}/legacy-scorecards/calendar/add-log`, {
                    data: {
                        generation,
                        email: userEmail,
                        env: process.env.REACT_APP_ENV,
                        ...changes
                    }
                }, {
                    headers: {
                        'authorization': `Bearer ${auth0AccessToken}`
                    }
                }).then(() => {
                    toast.info(`New Calendar version: ${generation} by ${userEmail}`)
                })
            })
        }
    }
    const handleSave = () => {
        const newCalendarRows = apiRef.current.getSortedRows().slice(-12).map((r, id) => {
            return {
                ...r,
                id,
            }
        })
        const added = _.differenceWith(newCalendarRows.map(removeId), calendar.map(removeId), _.isEqual)
        const removed = _.differenceWith(calendar.map(removeId), newCalendarRows.map(removeId), _.isEqual)
        setCalendar(newCalendarRows)
        setChanges({added, removed})
        setOpenOverwriteDialog(true)

    }

    const StyledDataGrid = styled(DataGrid)(() => ({
        [`& .${gridClasses.row}.for-removal`]: {
            backgroundColor: 'red',
            color: 'white'
        },
    }));


    return (
        <>
            <Button onClick={handleAddButton} disabled={isLoading}>Add row</Button>
            <Button onClick={handleRemoveButton} disabled={isLoading}>Remove row</Button>
            <Button onClick={handleSave} disabled={isLoading}>Save</Button>
            {!isLoading ? (
                <div>
                    <Paper>
                        <StyledDataGrid disableColumnMenu={true}
                                        loading={isLoading}
                                        disableColumnFilter={true}
                                        columns={headers.map(col => {
                                            return {
                                                sortable: false,
                                                hideable: false,
                                                editable: true,
                                                field: col
                                            }
                                        })}
                                        rows={calendar}
                                        apiRef={apiRef}
                                        hidden={isLoading}
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage < (apiRef.current.getRowsCount() - 12) ? "for-removal" : ""
                                        }
                        />
                    </Paper>
                    <CalendarChangeLog headers={headers}/>
                </div>
            ) : (
                <Paper>
                    <CircularProgress sx={{position: "relative", left: "50%"}}/>
                </Paper>
            )}
            <ConfirmationDialog
                isOpen={openOverwriteDialog}
                message={"Are you sure you want to overwrite calendar?"}
                handleClose={(value) => setOpenOverwriteDialog(value)}
                handleConfirm={(value) => handleConfirm(value)}
                title={"Commit Changes to GCS?"}
            />
        </>
    )
}

