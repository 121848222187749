import React, { useState } from "react";
import Page from "../../Page.jsx";
import { Box, Tab, Tabs } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { AwsParameterStore } from "./AwsParameterStore.jsx";
import { AwsSqs } from "./AwsSqs.jsx";
import { AwsLambda } from "./AwsLambda.jsx";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
export const RetailIntelligence = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <ToastContainer />
      <Page id={"period-release"} name={"Retail Intelligence"}>
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
          <Tabs orientation="vertical" value={value} onChange={handleChange}>
            <Tab label="Queues" {...a11yProps(0)} />
            <Tab label="Parameters" {...a11yProps(1)} />
            <Tab label="Lambda" {...a11yProps(2)} />
          </Tabs>
          <TabPanel style={{ flex: "1 1 auto" }} value={value} index={0}>
            <AwsSqs />
          </TabPanel>
          <TabPanel style={{ flex: "1 1 auto" }} value={value} index={1}>
            <AwsParameterStore />
          </TabPanel>
          <TabPanel style={{ flex: "1 1 auto" }} value={value} index={2}>
            <AwsLambda type={'retail'} />
          </TabPanel>
        </Box>
      </Page>
    </div>
  );
};
