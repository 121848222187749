import {SubscriptionHeader} from "../common/button/subscription/SubscriptionHeader.jsx";
import React from "react";

export const QUEUE_TASKS_QUERY = `mutation($ids: [String!]!){queueSubscriptions(ids: $ids)}`;
export const UPDATE_EMAILS_QUERY = 'mutation($emails: [String!]!){ updatePowertabsGlobalEmailList(emails: $emails)}';


export const PowertabsHeader = ({data, powertabsGlobalEmailList, selectedPeriod, periods, onUpdatePeriod}) => {
    const idResolver = (isQuarter, data) => {
        return data.filter((item) => isQuarter || item.reportType.toUpperCase() === 'BASE').map(item => item.id)
    }
    return (
        <SubscriptionHeader
            idResolver={idResolver}
            data={data}
            globalEmailList={powertabsGlobalEmailList}
            queueTasksQuery={QUEUE_TASKS_QUERY}
            updateEmailsQuery={UPDATE_EMAILS_QUERY}
            selectedPeriod={selectedPeriod}
            periods={periods}
            onUpdatePeriod={onUpdatePeriod}
        />
    )
}

export const PowertabsHeaderBuilder = (selectedPeriod, periods, onUpdatePeriod) => {
    return ({data, powertabsGlobalEmailList}) => PowertabsHeader({
        data,
        powertabsGlobalEmailList,
        selectedPeriod,
        periods,
        onUpdatePeriod,
    })
}