import '../../../../scss/PowerTabs.scss';
import * as React from "react";
import {useEffect, useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import {SubscriptionForm} from "../SubscriptionForm.jsx";
import {useParams} from "react-router-dom";
import {makeApolloClientCall} from "../../../../api/makeApolloApiCall.js";
import {CircularProgress} from "@mui/material";
import {AGGREGATION_PERIODS, PRODUCT_UNIVERSES} from "../constants.js";


export const SDN_QUERY_EXPANDED = `query($id: [String], $period: String){
  powertabsSubscriptions(id: $id, period: $period) {
            filePreFixName
            clientBrands{
                value:id
                displayName
            }
            reportType
            channels {
              value:id
              displayName
            }
            keyAccounts {
              value:id
              displayName
            }
            categories{
              value:id
              displayName
            }
            subcategories{
              value:id
              displayName
            }
            competitorBrands{
              value:id
              displayName
            }
            productAttributes{
              value:id
              displayName
            }
            customSlicers
            poshtaBrandCode
            toEmail
            ccEmail
            aggregateProductUniverses
            upcProductUniverse
            aggregationPeriods
            newTemplate
  }
}`

export const EditSubscription = () => {
    const [data, setData] = useState({})
    const {id} = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const searchParams = new URLSearchParams(window.location.search);
    const period = searchParams.get("period")

    useEffect(() => {
        makeApolloClientCall(SDN_QUERY_EXPANDED, false, {id, period})
            .then((response) => {
                setData(response.powertabsSubscriptions[0])
                setIsLoading(false)
            })
    }, [])

    if (isLoading) {
        return (<CircularProgress/>)
    }

    return (
        <div>
            <h1>{id}</h1>
            <SubscriptionForm initialState={({
                    id,
                    filePreFixName: data.filePreFixName,
                    poshtaBrandCode: data.poshtaBrandCode,
                    customSlicers: data.customSlicers,
                    report_type: data.reportType,
                    toEmail: data.toEmail,
                    ccEmail: data.ccEmail,
                    aggregateProductUniverses: data.aggregateProductUniverses.map(input => PRODUCT_UNIVERSES.find(item => item.value === input)),
                    upcProductUniverse: data.upcProductUniverse,
                    aggregationPeriods: data.aggregationPeriods.map(input => AGGREGATION_PERIODS.find(item => item.value === input)),
                    channels: data.channels,
                    keyAccounts: data.keyAccounts,
                    categories: data.categories,
                    subcategories: data.subcategories,
                    clientBrands: data.clientBrands,
                    competitorBrands: data.competitorBrands,
                    productAttributes: data.productAttributes,
                    newTemplate: data.newTemplate,
                    period
                }
            )
            }/>
        </div>
        )
}


