import {makeApolloClientCall} from "../../api/makeApolloApiCall.js";
import {useEffect, useState} from "react";
import Page from "../Page.jsx";
import {Table} from "../tables/Table.jsx";
import {addColumnsGroupHeader} from "../tables/util.js";
import {CircularProgress} from "@mui/material";
import * as React from "react";
import {toast} from "react-toastify";

export const GraphqlQueryTable = ({testId, query, generateColumns, pageName, optionalAboveTheTableComponent, tableData, setTableData, queryParams}) => {
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)
        if (tableData) {
            setData(tableData)
            setIsLoading(false)
            return
        }
        makeApolloClientCall(query, false, queryParams)
            .then((graphqlData) => {
                setData(graphqlData ?? [])
                if (setTableData) {
                    setTableData(graphqlData ?? [])
                }
                if (graphqlData.errors) {
                    throw Error(graphqlData.errors[0].message)
                }
            })
            .catch((error) => {
                console.log(error)
                setData({data: []})
                toast.error(`${error}`)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [query, tableData, setTableData, queryParams])

    useEffect(() => {
        if (data) {
            setIsLoading(false)
        }
    }, [data])

    return isLoading ? (<CircularProgress className={"spinning-loader"}/>) :
        (
            <div data-testid={testId}>
                <Page name={pageName}>
                    <div>{optionalAboveTheTableComponent && optionalAboveTheTableComponent(data)}</div>
                    <div className={'table-container'}>
                        {data?.data?.length === 0 ?
                            <p style={{textAlign: 'center'}}> No data to show. </p> :
                            <Table  columns={addColumnsGroupHeader('Data', generateColumns(data.data ?? []))} data={data.data?.map(d => {return {...d, ...queryParams}}) ?? []}/>
                        }
                    </div>
                </Page>
            </div>
        )
}
