import {makeApolloClientCall} from "../api/makeApolloApiCall.js";

export const CURRENT_PERIOD_QUERY = `
    query {
        data: sdnDeliveryShiningLanding763StandardRetailMetadataDimPeriodsCurrentPeriod {
            abbreviation
        }
    }
`

export const parseSpinsPeriod = (spinsPeriod) => {
    const re = /^(\d+)-[Pp]0*(\d+)$/gm
    const matcher = re.exec(spinsPeriod)
    if (matcher) {
        return {
            year: parseInt(matcher[1]),
            period: parseInt(matcher[2]),
        }
    }
    throw new Error("Invalid period format.")
}

export const formatPeriod = (year, period) => {
    const yearText = String(year).padStart(4, "0")
    const periodText = String(period).padStart(2, "0")
    return `${yearText}-P${periodText}`;
}

export const getPeriodBefore = (year, period) => {
    if (period === 1) {
        return {
            year: year - 1,
            period: 13,
        }
    }
    return {
        year: year,
        period: period - 1,
    }
}

export const getPeriodAfter = (year, period) => {
    if (period === 13) {
        return {
            year: year + 1,
            period: 1,
        }
    }
    return {
        year: year,
        period: period + 1,
    }
}

export const fetchLatestPeriod = async () => (await makeApolloClientCall(CURRENT_PERIOD_QUERY, false, {})).data.abbreviation