import {RunAllButton} from "./RunAllSubsriptionButton.jsx";
import {EmailTextBox} from "../../../../emailTextBox/EmailTextBox.jsx";
import {makeApolloClientCall} from "../../../../../api/makeApolloApiCall.js";
import {Dropdown} from "../../../../dropdown/Dropdown.jsx";



export const SubscriptionHeader = ({idResolver, data, globalEmailList, queueTasksQuery, updateEmailsQuery, selectedPeriod, periods, onUpdatePeriod}) => {
    return (
        <div className="powertabs-run-all">
            <div>
                <RunAllButton isQuarter={true} idResolver={idResolver} data={data} query={queueTasksQuery}/>
            </div>
            <div>
                <RunAllButton isQuarter={false} idResolver={idResolver} data={data} query={queueTasksQuery}/>
            </div>
            <div className={"global-emails"}>
                <EmailTextBox
                    label={"Global Emails"}
                    value={globalEmailList}
                    setError={(error) => console.error("=============> error: ", error)}
                    onChange={(value) => makeApolloClientCall(updateEmailsQuery, false, {emails: value})}
                />
            </div>
            <div>
                <Dropdown
                    onChange={(event) => onUpdatePeriod(event.target.value)}
                    data={periods.map((p) => {
                        return {
                            displayName: p,
                            value: p,
                        }
                    })}
                    label="Selected Period"
                    className="item"
                    required={true}
                    currentValue={selectedPeriod}
                />
            </div>
        </div>
    )
}

